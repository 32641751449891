import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { apiLogin, apiMe } from "../api/axios";
import { ILoginInput, ILoginOutput } from "../api/type";
import { FormButton } from "../component/form-button";
import { FormError } from "../component/form-error";
import { LOCALSTORAGE_TOKEN, UserRole, UserTestState } from "../constant";
import { useState } from "react";
export const TesterLogin = () => {
  const naviage = useNavigate();
  const queryClient = useQueryClient();
  const [isCheck, setCheck] = useState(false);

  useQuery("me", apiMe, {
    onSuccess: (data) => {
      if (data) {
        switch (data.testState) {
          case UserTestState.Pending:
            naviage(`/orientation?normid=${data.group[0].normId}`);
            break;

          case UserTestState.InProgress:
            naviage(`/test`);
            break;

          case UserTestState.Done:
            naviage(`/preview`);
            break;

          default:
            break;
        }
      }
    },
  });
  const {
    register,
    getValues,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm<ILoginInput>({ mode: "onChange" });

  const loginMutation = useMutation(apiLogin, {
    onSuccess: (data: ILoginOutput) => {
      if (data.ok && data.token) {
        localStorage.setItem(LOCALSTORAGE_TOKEN, data.token);
        queryClient.invalidateQueries("me");
      }
    },
  });

  const onSubmit = () => {
    if (!loginMutation.isLoading && isCheck) {
      const { name, password } = getValues();
      loginMutation.mutate({ name, password, role: UserRole.Tester });
    }
  };

  return (
    <div className="h-screen flex items-center justify-center min-w-min select-none">
      <video
        src="/lx_bg.mp4"
        autoPlay
        loop
        className="w-screen h-screen fixed min-w-[1400px] top-0 left-0 z-[-1] object-fill"
      />
      <div className="w-full max-w-[32rem]">
        <div className="bg-[rgba(34,34,34,.8)]  w-full py-8 px-2 text-center box-shadow: 3px 8px 23px rgba(0,0,0,.58) p-[ 40px 75px]">
          <h3 className=" font-medium text-2xl text-white ">
            LX Hausys 직무역량면접
          </h3>
          <hr className="mt-10 mx-5" />
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="grid gap-3 mt-5 px-5"
          >
            <p className=" text-sm text-white ">
              이름과 안내 받으신 인증키를 입력해 주십시오.
            </p>
            <input
              {...register("name", { required: "이름은 필수 입력값 입니다." })}
              placeholder="이름"
              required
              className="text-center bg-gray-100 shadow-inner focus:ring-2 focus:ring-gray-400 focus: outline-none my-1  py-2 mx-10 rounded-md"
            />
            {errors.name?.message && (
              <FormError errorMessage={errors.name?.message} />
            )}
            <input
              {...register("password", {
                required: "인증키는 필수 입력값 입니다.",
              })}
              type="password"
              placeholder="인증키"
              required
              className="text-center bg-gray-100 shadow-inner focus:ring-2 focus:ring-gray-400 focus: outline-none my-1  py-2 mx-10 rounded-md"
            />
            <p className="text-left text-sm text-white ">
              본 과제의 내용 및 진행 프로세스 등 면접과 관련된 모든 사항을
              어떠한 방법으로도 외부에 유출하지 않을 것을 서약합니다.
            </p>
            <p className="text-left text-sm text-white ">
              만약 외부 유출 시 관련 법령에 의거하여 처벌받을 수 있음을
              확인하였습니다.
            </p>
            <p className=" text-sm text-white pt-8 pb-2">
              위의 내용에 동의합니다.
              <input
                className="ml-3"
                type="checkbox"
                onChange={() => setCheck((prev) => !prev)}
              />
            </p>
            {errors.password?.message && (
              <FormError errorMessage={errors.password?.message} />
            )}
            <FormButton
              canClick={isValid && isCheck}
              loading={loginMutation.isLoading}
              actionText={"접속"}
            />
            {loginMutation.data?.error && (
              <FormError errorMessage={loginMutation.data.error} />
            )}
            <div className=" flex justify-end pt-5">
              <img src="/ci_logo.png" alt="" className="flex w-[160px]" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
